<template>
	<div>
		<a-descriptions bordered size="small">
			<template slot="title">
				<b>loan offer</b>
				<a-button type="primary" @click="loanWordVisible = true" style="margin-left: 20px">Download Template</a-button>
			</template>
			<a-descriptions-item :label="$t('table.loanInfo.addInfo.number')" :span="3">
				<a-input v-model="loanOfferNo" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.loanInfo.addInfo.file')" :span="3">
				<UploadPreviewFile :uploadFilePath.sync="loanOfferFile" :folderPath="$route.query.id"></UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions bordered title="Insurance Endorsement" size="small">
			<a-descriptions-item label="Insurance Endorsement Letter (optional)" :span="3">
				<UploadPreviewFile :uploadFilePath.sync="endorsementLetters" :folderPath="$route.query.id"></UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item label="Expiry Date" :span="3">
				<a-date-picker :valueFormat="dateFormatVal" type="date" v-model="expireDate" showTime />
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions bordered title="Loan Calculator" size="small">
			<a-descriptions-item label="Loan Calculator">
				<UploadPreviewFile :uploadFilePath.sync="loanCalculator" :folderPath="$route.query.id"> </UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>

		<a-descriptions class="table-descriptions" :title="$t('table.applyInfo.applicationLabel.supDoc')" size="small">
			<a-descriptions-item :span="3">
				<a-table :columns="supCloums" size="small" :rowKey="(record, index) => index" :dataSource="disburseSupplementFiles" :pagination="false" bordered>
					<span slot="fileName" slot-scope="text, obj">
						<a-input v-model="obj.filename" class="input_table" />
					</span>
					<span slot="file" slot-scope="obj">
						<a v-bind:href="$Util.getObsImageUrl($store.state.pageState.authObj, obj.file)" target="_blank">
							{{ !$Util.isEmpty(obj.file) ? obj.file.split('/').at(-1) : '' }}
						</a>
					</span>
					<span slot="fileRemark" slot-scope="text, obj">
						<a-input type="textarea" v-model="obj.note" :rows="4" :maxLength="300" />
					</span>
					<span slot="action" slot-scope="text, obj, index">
						<a @click="DelSup(index)">{{ $t('page.delete') }}</a>
					</span>
				</a-table>
			</a-descriptions-item>
			<a-descriptions-item>
				<UploadPreviewFile
					v-show="disburseSupplementFiles?.length < 10"
					:uploadFilePath.sync="disburseSupplementFiles"
					:folderPath="$route.query.id"
					:isPreviewFile="false"
				></UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>

		<a-modal v-model="loanWordVisible" title="Download Template" @ok="exportWordDocx">
			<a-checkbox-group v-model="checkedWord" :options="optionsStatement" />
		</a-modal>
		<div class="btn_box">
			<a-button @click="SaveData" type="primary">{{ $t('operate.save') }}</a-button>
		</div>
	</div>
</template>
<script>
import { apiEmergencyDisburseSave, apiEmergencyDetail } from '@/api/emergencyLoan'
import { exportWordDocx } from '@/utils/exportWordDocx'
import moment from 'moment'
export default {
	data() {
		return {
			loanInfoData: null,
			checkedWord: [],
			loanWordVisible: false,
			optionsStatement: [
				{ label: 'Loan Offer', value: 'loan' },
				{ label: 'Security Agreement', value: 'security' }
			],
			dateFormatVal: 'YYYY-MM-DD HH:mm:ss',
			loanOfferFile: '',
			loanOfferNo: '',
			expireDate: '',
			endorsementLetters: [],
			loanCalculator: '',
			loanType: [
				{
					name: 'Emergency Loan',
					value: 5,
					interestRateOptions: [0.02, 0.025, 0.03, 0.035, 0.04, 0.05, 0.06, 0.075, 0.08, 0.1],
					installmentsOptions: Array.from({ length: 36 }, (_, i) => 1 + i),
					descriptionsItem: ['loanProcessingFee', 'mortgageRegistrationAmount', 'exciseDuty'],
					defaultsValue: {
						interestRate: 0.1,
						installmentsNumbers: 3,
						mortgageRegistrationAmount: 2500
					},
					defScale: {
						exciseDuty: '15%'
					},
					aliasItem: [],
					prefixType: 'EBC '
				},
				{
					name: 'Cash Pap',
					value: 6,
					interestRateOptions: [0.02, 0.025, 0.03, 0.035, 0.04, 0.05, 0.06, 0.075, 0.08, 0.1],
					installmentsOptions: Array.from({ length: 36 }, (_, i) => 1 + i),
					descriptionsItem: ['loanProcessingFee', 'creditLifeInsuranceFee', 'mortgageRegistrationAmount', 'applicationFee', 'exciseDuty'],
					defaultsValue: {
						interestRate: 0.06,
						installmentsNumbers: 3,
						creditLifeInsuranceFee: 0,
						applicationFee: 1600,
						mortgageRegistrationAmount: 2500
					},
					defScale: {
						exciseDuty: '15%'
					},
					aliasItem: [],
					prefixType: 'CBC '
				}
			],
			addrMap: {},
			disburseSupplementFiles: []
		}
	},
	computed: {
		supCloums() {
			return [
				{
					// 文件名称
					title: this.$t('table.applyInfo.applicationLabel.supCloums.fileName'),
					scopedSlots: { customRender: 'fileName' },
					align: 'center',
					width: 300
				},
				{
					// 文件
					title: this.$t('table.applyInfo.applicationLabel.supCloums.file'),
					scopedSlots: { customRender: 'file' },
					align: 'center'
				},
				{
					// 文件备注
					title: this.$t('table.applyInfo.applicationLabel.supCloums.fileRemark'),
					scopedSlots: { customRender: 'fileRemark' },
					align: 'center'
				},
				{
					// 操作
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center',
					width: 100
				}
			]
		}
	},
	created() {
		this.$nextTick(() => {
			apiEmergencyDetail({
				id: this.$route.query.id
			}).then((res) => {
				this.loanInfoData = res
				this.loanOfferFile = res.emergencyFormInfo.loanOfferFile
				this.loanOfferNo = res.emergencyFormInfo.loanOfferNo
				this.expireDate = res.emergencyFormInfo.expireDate
				this.endorsementLetters = res.emergencyFormInfo.endorsementLetters
				this.loanCalculator = res.emergencyFormInfo.loanCalculator
				this.disburseSupplementFiles = res.emergencyFormInfo.disburseSupplementFiles || []
			})
			// apiBranchManList().then((result) => {
			// 	result.content.map((item) => {
			// 		this.addrMap[item.id] = item.address
			// 	})
			// })
		})
	},
	methods: {
		moment: moment,
		closeTab(targetKey) {
			this.$store.commit('tabs/delTab', targetKey)
			this.$store.commit('tabs/setActiveTab', '/home/loandata/loanlist')
		},
		// 提交表单
		SaveData() {
			let params = {
				id: this.loanInfoData.emergencyFormInfo.id,
				loanId: this.loanInfoData.emergencyFormInfo.loanId,
				loanOfferFile: this.loanOfferFile,
				loanOfferNo: this.loanOfferNo,
				expireDate: this.expireDate,
				endorsementLetters: this.endorsementLetters,
				loanCalculator: this.loanCalculator,
				disburseSupplementFiles: this.disburseSupplementFiles,
			}
			apiEmergencyDisburseSave(params)
				.then(() => {
					this.$message.success('success')
					this.closeTab(this.$route.fullPath)
					this.$router.push({
						path: this.$route.query.from,
						query: this.$route.query.id
							? {
									id: this.$route.query.id
							  }
							: {}
					})
				})
				.catch((err) => {
					console.log(err)
				})
		},
		exportWordDocx() {
			const basePrefix = process.env.NODE_ENV === 'development' ? `/bccms/` : `/`
			if (this.checkedWord.length === 0) {
				this.$message.error('Select Empty')
				return false
			}
			const prefixType = {
				5: 'EBC ',
				6: 'CBC '
			}
			if (this.checkedWord.includes('loan')) {
				const loanOfferData = {
					CaseID: this.loanInfoData.casePrefix + '-' + this.loanInfoData.caseId,
					FullName: this.loanInfoData.personalInfo.basicFullName,
					LoanType: this.$t(`table.applyInfo.financialLabel.loanMap.${this.loanInfoData.emergencyFormInfo.loanInfo.loanType}`),
					LoanTypeRNo:
						prefixType[this.loanInfoData.emergencyFormInfo.loanInfo.loanType] + this.loanInfoData.emergencyFormInfo.logbook.registrationNumber,
					Mobiles: this.loanInfoData.personalInfo.mobiles.map((item) => item.mobile).join(' ,'),
					BasicSex: this.loanInfoData.personalInfo.basicSex === 1 ? 'Sir' : 'Madam',
					LoanAmountDecimal: this.loanInfoData.emergencyFormInfo.loanInfo.applyAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.00',
					DownloadDate: moment().format('Do MMMM YYYY'),
					NationalID: this.loanInfoData.personalInfo.basicIdentityCard,
					PIN: this.loanInfoData.personalInfo.pin.pinNum,
					LoanInstallmentsNumber: this.loanInfoData.emergencyFormInfo.loanInfo.installmentsNumbers,
					LoanPeriod: (this.loanInfoData.emergencyFormInfo.loanInfo.interestRate * 100).toFixed(2) + '%',
					CommencingOnDate: moment().add(1, 'M').format('Do MMMM YYYY'),
					EndingOnDate: moment()
						.add(this.loanInfoData.emergencyFormInfo.loanInfo.installmentsNumbers + 1, 'M')
						.format('Do MMMM YYYY'),
					FirstMonthInstallment: this.loanInfoData.emergencyFormInfo.loanInfo.firstMonthRepay.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
					OtherMonthInstallment: this.loanInfoData.emergencyFormInfo.loanInfo.otherMonthRepay.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
					VehicleRegistrationNumber: this.loanInfoData.emergencyFormInfo.logbook.registrationNumber,
					Make: this.loanInfoData.emergencyFormInfo.logbook.make,
					Model: this.loanInfoData.emergencyFormInfo.logbook.model,
					EngineNO: this.loanInfoData.emergencyFormInfo.logbook.engineNo,
					ChassisNO: this.loanInfoData.emergencyFormInfo.logbook.frameNumber,
					AddrMap: this.addrMap[this.loanInfoData.storeId] ? this.addrMap[this.loanInfoData.storeId] : '',
					LoanAmount: this.loanInfoData.emergencyFormInfo.loanInfo.applyAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
					NetAmount: this.loanInfoData.emergencyFormInfo.loanInfo.amountPayable.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
					LPFRate: this.loanType[this.loanInfoData.emergencyFormInfo.loanInfo.loanType - 5].defScale.loanProcessingFee,
					CIRate: this.loanType[this.loanInfoData.emergencyFormInfo.loanInfo.loanType - 5].defScale.creditLifeInsuranceFee
						? `d.	Credit life will be charged at rate of ${
								this.loanType[this.loanInfoData.emergencyFormInfo.loanInfo.loanType - 5].defScale.creditLifeInsuranceFee
						  } on the principal loan borrowed.`
						: '',
					LoanAmountList: this.loanType[this.loanInfoData.emergencyFormInfo.loanInfo.loanType - 5].descriptionsItem.map((deItem) => {
						return {
							Name:
								this.$t(
									`table.applyInfo.loanApplyLabel.${
										this.loanType[this.loanInfoData.emergencyFormInfo.loanInfo.loanType - 5].aliasItem.includes(deItem) ? deItem + 'Alias' : deItem
									}`
								) +
								(this.loanType[this.loanInfoData.emergencyFormInfo.loanInfo.loanType - 5].defScale[deItem]
									? this.loanType[this.loanInfoData.emergencyFormInfo.loanInfo.loanType - 5].defScale[deItem]
									: ''),
							Amount: this.loanInfoData.emergencyFormInfo.loanInfo[deItem].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
						}
					})
				}
				loanOfferData.LoanAmountList = loanOfferData.LoanAmountList.filter((item) => Number(item.Amount.replace(/,/g, '')) > 0)
				exportWordDocx(`${basePrefix}loanOffer.docx`, loanOfferData, 'Loan Offer.docx')
			}
			if (this.checkedWord.includes('security')) {
				exportWordDocx(
					`${basePrefix}securityAgreement.docx`,
					{
						FullName: this.loanInfoData.personalInfo.basicFullName,
						DownloadDate: moment().format('Do MMMM YYYY'),
						LoanAmount: 'Kshs.' + this.loanInfoData.emergencyFormInfo.loanInfo.applyAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
						MotorVehicle: 'Motor vehicle',
						Make: this.loanInfoData.emergencyFormInfo.logbook.make,
						YearOfManufacture: this.loanInfoData.emergencyFormInfo.logbook.year,
						VehicleRegistrationNumber: this.loanInfoData.emergencyFormInfo.logbook.registrationNumber,
						Model: this.loanInfoData.emergencyFormInfo.logbook.model,
						EngineNO: this.loanInfoData.emergencyFormInfo.logbook.engineNo,
						ChassisNO: this.loanInfoData.emergencyFormInfo.logbook.frameNumber
					},
					'Security Agreement.docx'
				)
			}
			this.loanWordVisible = false
			this.checkedWord = []
		},
		// 补充文件删除
		DelSup(idx) {
			this.disburseSupplementFiles.splice(idx, 1)
		}
	}
}
</script>
<style scoped lang="less">
.line_span {
	display: inline-block;
	vertical-align: top;
	width: 200px;
	text-align: right;
	padding-right: 10px;
}
.ant-input,
.ant-select {
	width: 200px;
}
.file {
	left: 0;
	top: 0;
	width: 100%;
	bottom: 0;
	position: absolute;
	opacity: 0;
	cursor: pointer;
}
.file:hover {
	opacity: 0;
	text-decoration: none;
}
.input_box {
	width: 200px;
	vertical-align: top;
	display: inline-block;
	margin-right: 20px;
}
</style>
